import React from 'react'
import Message from './Message'
import Username from './Username'


const Packet = () => {
  return (
    <div>
        <Username/>
        <Message/>
    </div>
  )
}

export default Packet