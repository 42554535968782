import React from 'react'

const Username = () => {
  return (
    <div className='text-xl'>
        Username
    </div>
  )
}

export default Username